import * as React from "react"
import { Link } from "gatsby"
import CookieConsent from "react-cookie-consent"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = (
      <h1 className="main-heading">
        <Link to="/">{title}</Link>
      </h1>
    )
  } else {
    header = (
      <Link className="header-link-home" to="/">
        {title}
      </Link>
    )
  }

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="global-header">{header}</header>
      <main>{children}</main>
      <footer>
        <Link to="/privacy-policy">Privacy Policy</Link>
         {' '}
         <Link to="/legal-notice">Legal Notice</Link>
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          declineButtonText="Decline"
          cookieName="gatsby-gdpr-google-analytics"
        >
          We use cookies to track user behavior via Google Analytics. <Link to="/privacy-policy">Learn More</Link>
        </CookieConsent>
      </footer>
    </div>
  )
}

export default Layout
